<div class="p-5">
  <div class="is-flex is-justify-content-space-between">
    <h2>{{ 'contact.us' | translate }}</h2>
    <button
      mat-icon-button
      aria-label="Close"
      type="button"
      (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="is-flex is-flex-direction-column mt-3">
    <div class="is-flex mr-3 mb-4">
      <mat-icon class="rounded">mail_outlined</mat-icon>
      <p class="header-label">
        <a href="mailto:contact@acs-ami.com">contact&#64;acs-ami.com</a>
      </p>
    </div>
    <div class="is-flex mr-3">
      <mat-icon class="rounded">call_outlined</mat-icon>
      <p class="header-label">
        <a href="tel:+33140479100">+33 (0)1 40 47 91 00</a>
      </p>
    </div>
  </div>
</div>
