import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
  ViewChild
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Routing } from '../../app.routes';
import { PoliciesMode } from '../../shared/helpers/client.utils';
import { TranslatePipe } from '../../shared/pipes/translate.pipe';
import { GlobalStore } from '../../shared/services/global.store';
import { StepperService } from '../../shared/services/stepper.service';

@Component({
  selector: 'acs-stepper',
  standalone: true,
  imports: [
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    TranslatePipe
  ],
  templateUrl: './stepper.component.html',
  styles: `
    @media only screen and (min-width: 1224px) {
      ::ng-deep
        .mat-stepper-label-position-bottom
        .mat-horizontal-stepper-header
        .mat-step-icon {
        height: 40px;
        width: 40px;
        top: -8px;
      }

      ::ng-deep .mat-stepper-horizontal-line.mat-stepper-horizontal-line {
        border-top-style: dashed;
        border-top-width: 2px;
      }

      ::ng-deep
        .mat-stepper-label-position-bottom
        .mat-horizontal-stepper-header:not(:last-child)::after,
      ::ng-deep
        .mat-stepper-label-position-bottom
        .mat-horizontal-stepper-header:not(:last-child)::before,
      ::ng-deep
        .mat-stepper-label-position-bottom
        .mat-horizontal-stepper-header:not(:first-child)::before {
        border-top-style: dashed !important;
        border-top-width: 2px !important;
      }
    }

    ::ng-deep :root {
      --mat-stepper-header-selected-state-icon-background-color: #3bafdd;
      --mat-stepper-header-edit-state-icon-background-color: #cbf8b4;
      --mat-stepper-header-edit-state-icon-foreground-color: #136d1f;
      --mat-stepper-line-color: #3bafdd;
      --mat-stepper-header-icon-background-color: rgba(10, 34, 44, 0.12);
      --mat-stepper-header-icon-foreground-color: rgba(10, 34, 44, 0.26);
      --mat-stepper-container-color: #f5fafc;
      --mat-stepper-header-done-state-icon-background-color: rgba(
        10,
        34,
        44,
        0.26
      );
    }

    ::ng-deep .mat-step-icon .mat-icon {
      font-weight: 700;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent implements AfterViewInit {
  @ViewChild('stepper') private stepper: MatStepper | undefined;
  readonly #stepperService: StepperService = inject(StepperService);
  readonly #router: Router = inject(Router);
  readonly #globalStore = inject(GlobalStore);
  #isAfterViewInit: boolean = false;
  public validProject = new FormControl('');
  public validInfo = new FormControl('');
  public validTarif = new FormControl('');
  public validSouscription = new FormControl('');

  public stepperIndex: Signal<number> = computed(() => {
    const step = this.#stepperService.$step();
    if (this.stepper) {
      this.setStepValidation(step);
      //Ici on sait qu'on a passer l'initilisation de tout le composant
      this.#isAfterViewInit = true;
    }
    return step;
  });

  /**
   * Suivi des etapes de velidation du stepper
   * On init a 0 tout les formControl a required: true
   * sur chaque etape on envele cette contrainte et on fait avencer le stepper
   * @param step
   * @private
   */
  private setStepValidation(step: number): void {
    if (step === 0) {
      this.validProject.setErrors({ required: true });
      this.validInfo.setErrors({ required: true });
      this.validTarif.setErrors({ required: true });
      this.validTarif.setErrors({ required: true });
    }
    if (step >= 1) {
      this.validProject.reset();
      this.stepper?.next();
    }
    if (step >= 2) {
      this.validInfo.reset();
      this.stepper?.next();
    }
    if (step >= 3) {
      this.validTarif.reset();
      this.stepper?.next();
    }
  }

  /**
   * On attend que le composant soit bien init pour passer dans le router
   * Sur chaque step de clique on repasse le signal a 0 pour qu'a l'init du composant ca soit la bonne step qui soit choisi
   * on Remet le formControl de la step qu'on quitte a required:true pour empecher la navigation sur ce composant
   * On met a jour le path du guard a chaque chamgenement de step
   * @param index number numero de la step selectionnée
   */
  public onChangeIndex(index: number): void {
    const param = this.#globalStore.getUrlParamInfo();

    if (this.#isAfterViewInit) {
      this.#stepperService.$step.update(() => 0);
      switch (index) {
        case 0:
          this.#globalStore.setGuardPath('');
          if (param?.entity === PoliciesMode.CLIENTS) {
            this.#router.navigate([Routing.PROJECT], {
              queryParams: {
                token:
                  this.#globalStore.getVisitorParam()?.visitor_token ??
                  param.token,
                uuid: param?.uuid,
                producer_code: this.#globalStore.getVisitorParam()?.broker_id,
                mode: param?.mode,
                entity: param?.entity
              }
            });
          } else {
            this.#router.navigate([Routing.PROJECT], {
              queryParams: {
                token: param?.token,
                uuid: param?.uuid,
                producer_code: param?.producerCode,
                mode: param?.mode,
                entity: param?.entity
              }
            });
          }
          break;
        case 1:
          this.#globalStore.setGuardPath(Routing.INFO);
          this.#router.navigateByUrl(Routing.INFO);
          this.validInfo.setErrors({ required: true });
          break;
        case 2:
          this.validTarif.setErrors({ required: true });
          this.#router.navigate([Routing.TARIFS]);
          this.#globalStore.setGuardPath(Routing.TARIFS);
          break;
      }
    }
  }

  /**
   * Le Mat stepper est init seulement apres ce hook donc initialisation quand on a le mat stepper
   */
  public ngAfterViewInit(): void {
    this.setStepValidation(this.stepperIndex());
  }
}
