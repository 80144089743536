import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslatePipe } from '../../../../shared/pipes/translate.pipe';

@Component({
  selector: 'header-dialog',
  templateUrl: 'header-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatIconModule,
    MatButtonModule,
    TranslatePipe
  ],
  styles: `
    .rounded {
      background-color: #cdf0f3;
      height: 3rem;
      width: 3rem;
      padding: 0.75rem;
      border-radius: 50%;
    }

    .header-label {
      margin-top: 0.75rem;
      margin-left: 0.5rem;
      color: #154f5f;
      font-weight: 500;
      font-size: 0.875rem;
    }

    a {
      font-weight: 500;
      font-size: 1rem;
      color: #154f5f;
    }
  `
})
export class HeaderDialogComponent {
  readonly dialogRef = inject(MatDialogRef<HeaderDialogComponent>);

  onNoClick(): void {
    this.dialogRef.close();
  }
}
