import { Component, computed, inject, Signal } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { LanguageService } from '../../shared/services/language.service';
import { LanguageStore } from '../../shared/services/language.store';
import { FooterComponent } from './footer/footer.component';
import { StepperComponent } from '../../view/stepper/stepper.component';
import { HeaderComponent } from './header/header.component';
import { StepperService } from '../../shared/services/stepper.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, StepperComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  languageStore = inject(LanguageStore);
  readonly #stepperService: StepperService = inject(StepperService);
  protected $showStepper: Signal<boolean> = computed(() =>
    this.#stepperService.$isVisible()
  );

  constructor() {
    inject(LanguageService).initialiseLanguages().subscribe();
  }
}
