import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { Routing } from '../../app.routes';

export const acsApiInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  return next(req).pipe(
    catchError((err: any) => {
      if (err.status !== 400) {
        router.navigate([Routing.UNAUTHORIZED], {
          state: { data: err.error.message }
        });
      }
      throw err;
    })
  );
};
