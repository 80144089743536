<div class="is-flex is-justify-content-space-between bg-white p-3">
  <img src="/logoACS.png" alt="logo acs" class="logo mt-2" />
  <div class="is-flex is-flex-direction-column">
    <div class="is-flex is-justify-content-space-between">
      <button
        mat-fab
        aria-label="Contacts"
        class="is-hidden-desktop"
        (click)="openDialog()">
        <mat-icon>perm_phone_msg</mat-icon>
      </button>
      <div class="is-hidden-touch is-flex mt-3">
        <div class="is-flex mr-3">
          <mat-icon class="rounded">mail_outlined</mat-icon>
          <p class="header-label">
            <a href="mailto:contact@acs-ami.com">contact&#64;acs-ami.com</a>
          </p>
        </div>
        <div class="is-flex mr-3">
          <mat-icon class="rounded">call_outlined</mat-icon>
          <p class="header-label">+33 (0)1 40 47 91 00</p>
        </div>
      </div>
      <mat-form-field class="width-4rem ml-2 header-form" appearance="outline">
        <mat-select
          [value]="$language()"
          (selectionChange)="languageChange($event.value)">
          <mat-option value="fr">Fr</mat-option>
          <mat-option value="en">En</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mr-2 has-text-right">
      <span> {{ 'key.partner.code' | translate }}: {{ $code() }}</span>
    </div>
  </div>
</div>
