<footer>
  <mat-divider class="screen-xl"></mat-divider>
  <div class="flex-screen-xl">
    <img src="/Fredexpat.png" alt="Image expatriation" height="187" />
    <mat-divider class="screen-sm"></mat-divider>
    <div class="text-center flex-column align-item-start is-flex-grow-1 mt-sm">
      <span class="acs-footer-headliner">
        {{ 'footer.acs' | translate }}
      </span>
      <div class="column-sm">
        <div innerHTML="{{ 'footer.contact' | translate }}"></div>
        <div innerHTML="{{ 'footer.mentions.legales' | translate }}"></div>
        <div innerHTML="{{ 'footer.condition.ventes' | translate }}"></div>
        <div class="acs-version">ACS 2024 v{{ version }}</div>
      </div>
    </div>
  </div>
</footer>
