import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { version } from '../../../../../package.json';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [MatDividerModule, TranslatePipe],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  protected version = version;
}
