import { NgOptimizedImage } from '@angular/common';
import { Component, computed, inject, Signal } from '@angular/core';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
  MatOption,
  MatSelect,
  MatSelectChange
} from '@angular/material/select';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { GlobalStore } from '../../../shared/services/global.store';
import {
  LanguageCode,
  LanguageStore
} from '../../../shared/services/language.store';
import { HeaderDialogComponent } from './header-dialog/header-dialog.component';
import {
  AvailableLanguage,
  LanguageService
} from '../../../shared/services/language.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatMiniFabButton,
    MatIconModule,
    MatFormFieldModule,
    MatSelect,
    MatOption,
    MatFabButton,
    TranslatePipe
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  readonly #languageService: LanguageService = inject(LanguageService);
  public $language: Signal<LanguageCode> = computed(() =>
    this.#languageService.$userLanguage()
  );

  readonly #languageStore: LanguageStore = inject(LanguageStore);
  readonly #globalStore: GlobalStore = inject(GlobalStore);
  readonly #dialog = inject(MatDialog);

  public $code: Signal<string | null> = computed(() =>
    this.#globalStore.$producerCode()
  );

  public languageChange(code: LanguageCode): void {
    this.#languageStore.setCurrentLanguageCode(code);
  }

  public openDialog(): void {
    this.#dialog.open(HeaderDialogComponent, {
      width: '280px',
      height: '220px'
    });
  }
}
