import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection
} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  RouteReuseStrategy,
  withComponentInputBinding,
  withInMemoryScrolling
} from '@angular/router';

import { IMAGE_CONFIG, registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withFetch,
  withInterceptors
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { version } from '../../package.json';
import { routes } from './app.routes';
import { acsApiInterceptor } from './core/interceptors/acs-api.interceptor';
import { CustomDateAdapter } from './core/services/date-adapter';
import { CustomRouteReuseStrategy } from './core/strategy/custom-route.strategy';

registerLocaleData(localeFr);

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: ['DD/MM/YYYY', 'YYYY-MM-DD']
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled'
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withComponentInputBinding(),
      inMemoryScrollingFeature
    ),
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptors([acsApiInterceptor])),
    importProvidersFrom(
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule
    ),
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      }
    },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const currentVersion: string = version;
        const cachedVersion = sessionStorage.getItem('appVersion');

        if (cachedVersion && cachedVersion !== currentVersion) {
          sessionStorage.setItem('appVersion', currentVersion);
          window.location.reload();
        } else {
          sessionStorage.setItem('appVersion', currentVersion);
        }
      }
    }
  ]
};
