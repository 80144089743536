<app-header></app-header>
@if ($showStepper()){
<acs-stepper></acs-stepper>
}

<div class="p-5 bg-primary">
  <router-outlet></router-outlet>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
