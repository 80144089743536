<mat-stepper
  labelPosition="bottom"
  [linear]="true"
  class="mat-step-header"
  #stepper
  (selectedIndexChange)="onChangeIndex($event)">
  <mat-step
    label="{{ 'my.project' | translate }}"
    state="edit"
    [editable]="stepperIndex() > 0"
    [stepControl]="validProject">
  </mat-step>
  <mat-step
    label="{{ 'my.infos' | translate }}"
    state="done"
    [editable]="stepperIndex() > 1"
    [stepControl]="validInfo">
  </mat-step>
  <mat-step
    label="{{ 'my.tarifs' | translate }}"
    state="done"
    [editable]="stepperIndex() > 2"
    [stepControl]="validTarif">
  </mat-step>
  <mat-step
    label="{{ 'my.sub' | translate }}"
    state="done"
    [editable]="false"
    [stepControl]="validSouscription">
  </mat-step>
  <!-- Icon overrides. -->
  <ng-template matStepperIcon="edit">
    <mat-icon class="is-size-6">check</mat-icon>
  </ng-template>
</mat-stepper>
